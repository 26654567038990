import {
	type CartFragment,
	type CartLineItemUpdateInput,
	type CartLineItemsRemoveMutation as CartLineItemsRemoveMutationType,
	type CartLineItemsRemoveMutationVariables,
	type CartLineItemsUpdateMutation as CartLineItemsUpdateMutationType,
	type CartLineItemsUpdateMutationVariables,
	type Maybe,
	graphql,
} from "@commerce-frontend/types";
import type { GqlResponse } from "@labdigital/graphql-fetcher";
import { useClientGqlFetcher } from "@labdigital/graphql-fetcher";
import {
	type UseMutateAsyncFunction,
	useMutation,
	useQuery,
	useQueryClient,
} from "@tanstack/react-query";
import { useParams } from "next/navigation";
import { getJWT } from "~/lib/auth/helpers";
import { useStoreConfig } from "~/lib/store-config/context";

export const Cart = graphql(/* GraphQL */ `
	fragment Cart on Cart {
		...CartOverviewFragment
		# Base fields required for react-query logic
		id {
			id
			version
		}
		subTotal {
			net {
				...PriceFragment
			}
			gross {
				... PriceFragment
			}
			taxes {
				value {
					...PriceFragment
				}
				rate
			}
		}

		total {
			net {
				...PriceFragment
			}
			gross {
				... PriceFragment
			}
			taxes {
				value {
					...PriceFragment
				}
				rate
			}
		}

		shippingCosts {
			net {
				...PriceFragment
			}
			gross {
				... PriceFragment
			}
			tax {
				value {
					...PriceFragment
				}
				rate
			}
		}
	}
`);

const GetCart = graphql(/* GraphQL */ `
	query GetCart($storeContext: StoreContextInput!) {
		cart(storeContext: $storeContext) {
			...Cart
		}
	}
`);

const CartLineItemsRemoveMutation = graphql(/* GraphQL */ `
	mutation CartLineItemsRemove(
		$storeContext: StoreContextInput!
		$id: CartIdentifierInput!
		$lineItemIds: [String!]!
	) {
		cartLineItemsRemove(
			storeContext: $storeContext
			id: $id
			lineItemIds: $lineItemIds
		) {
			...Cart
		}
	}
`);

const CartLineItemsUpdateMutation = graphql(/* GraphQL */ `
	mutation CartLineItemsUpdate(
		$storeContext: StoreContextInput!
		$id: CartIdentifierInput!
		$lineItems: [CartLineItemUpdateInput!]!
	) {
		cartLineItemsUpdate(
			storeContext: $storeContext
			id: $id
			lineItems: $lineItems
		) {
			...Cart
		}
	}
`);

/**
 * Handles all the logic to fetch and mutate the cart with GraphQL
 * @returns
 */
export const useCart = () => {
	const storeConfig = useStoreConfig();
	const queryKey = ["cart"] as const;
	const client = useQueryClient();
	const gqlClientFetch = useClientGqlFetcher();
	const params = useParams();
	const locale =
		storeConfig.locales.find((locale) => locale === params?.locale) ??
		storeConfig.defaultLocale;

	/**
	 * Most GraphQL mutations return the updated cart, so we update the cache manually
	 * instead of invalidating and refetching the cart after every update
	 */
	const setCartCache = (cart: Maybe<CartFragment>) => {
		client.setQueryData(queryKey, cart ?? null);
	};

	// Fetch the latest version of the cart
	const {
		isLoading,
		isFetching,
		isStale,
		data: cart,
		refetch: refetchCart,
	} = useQuery({
		queryKey,
		enabled: Boolean(getJWT()),
		queryFn: async () => {
			const response = await gqlClientFetch(GetCart, {
				storeContext: {
					storeKey: storeConfig.storeKey,
					currency: storeConfig.currency,
					locale,
				},
			});

			return response.data?.cart ?? null;
		},
	});

	const {
		isPending: isUpdatingLineItems,
		mutateAsync: updateLineItemsMutation,
	} = useMutation({
		mutationFn: (variables: CartLineItemsUpdateMutationVariables) =>
			gqlClientFetch<
				CartLineItemsUpdateMutationType,
				CartLineItemsUpdateMutationVariables
			>(CartLineItemsUpdateMutation, variables),
		onSuccess: async (response) => {
			// TODO: Create sort of "smart" normalized cache just for the cart (what apollo/urql/relay do but simpler)
			await client.invalidateQueries({ queryKey: ["cart", "quantity"] });
			setCartCache(response.data?.cartLineItemsUpdate ?? null);
		},
		/**
		 * Optimistically update the cart with the new line item for better UX
		 */
		onMutate: async (change) => {
			// Cancel any outgoing refetches (so they don't overwrite our optimistic update)
			await client.cancelQueries({ queryKey });
			// Snapshot the previous value
			const previousCart: CartFragment | undefined =
				client.getQueryData(queryKey);

			const updatedLineItems = Array.isArray(change.lineItems)
				? change.lineItems
				: [change.lineItems];

			// Update the cart with the new line item
			if (previousCart) {
				const newCart = {
					...previousCart,
					lineItems: previousCart.lineItems.map((lineItem) => {
						// Update existing lineItem with quantity from the new line item
						const updatedLineItem = updatedLineItems.find(
							(updatedLineItem) => updatedLineItem.id === lineItem.id,
						);

						if (updatedLineItem) {
							return {
								...lineItem,
								quantity: updatedLineItem.quantity,
							};
						}

						return lineItem;
					}),
				};

				setCartCache(newCart);
			}

			return { previousCart };
		},
		onError: (_err, _newCart, context) => {
			// Because we use optimistic updates we need to revert the changes when the mutation fails
			setCartCache(context?.previousCart ?? null);
		},
	});

	const {
		isPending: isRemovingLineItems,
		mutateAsync: removeLineItemsMutation,
	} = useMutation({
		mutationFn: (variables: CartLineItemsRemoveMutationVariables) =>
			gqlClientFetch<
				CartLineItemsRemoveMutationType,
				CartLineItemsRemoveMutationVariables
			>(CartLineItemsRemoveMutation, variables),
		onSuccess: async (response) => {
			// TODO: Create sort of "smart" normalized cache just for the cart (what apollo/urql/relay do but simpler)
			await client.invalidateQueries({ queryKey: ["cart", "quantity"] });
			setCartCache(response.data?.cartLineItemsRemove ?? null);
		},
		/**
		 * Optimistically update the cart with the new line item for better UX
		 */
		onMutate: async (_change) => {
			// Cancel any outgoing refetches (so they don't overwrite our optimistic update)
			await client.cancelQueries({ queryKey });
			// Snapshot the previous value
			const previousCart: CartFragment | undefined =
				client.getQueryData(queryKey);

			return { previousCart };
		},
		onError: (_err, _newCart, context) => {
			// Because we use optimistic updates we need to revert the changes when the mutation fails
			setCartCache(context?.previousCart ?? null);
		},
	});

	return {
		isLoading,
		isUpdating: [isFetching, isUpdatingLineItems, isRemovingLineItems].some(
			Boolean,
		), // Group all loading states
		isStale,
		refetchCart,
		invalidateCache: () => client.invalidateQueries({ queryKey: ["cart"] }),
		...useCartBase({
			cart: cart ?? undefined,
			updateLineItemsMutation,
			removeLineItemsMutation,
		}),
	};
};

type UseCartBaseProps = {
	cart?: CartFragment;
	updateLineItemsMutation: UseMutateAsyncFunction<
		GqlResponse<CartLineItemsUpdateMutationType>,
		unknown,
		CartLineItemsUpdateMutationVariables
	>;
	removeLineItemsMutation: UseMutateAsyncFunction<
		GqlResponse<CartLineItemsRemoveMutationType>,
		unknown,
		CartLineItemsRemoveMutationVariables
	>;
};

/**
 * Base version of the hook that handles the UI actions and sends them off to the GraphQL hook
 * as well as handling the response. This is separated from the main hook so that it can be
 * tested without having to mock a network layer.
 */
export const useCartBase = ({
	cart,
	removeLineItemsMutation,
	updateLineItemsMutation,
}: UseCartBaseProps) => {
	const storeConfig = useStoreConfig();
	const params = useParams();
	const locale =
		storeConfig.locales.find((locale) => locale === params?.locale) ??
		storeConfig.defaultLocale;

	const removeLineItems = (lineItemIds: string[]) =>
		cart
			? removeLineItemsMutation({
					// TODO: Get these from a store config context
					storeContext: {
						locale,
						currency: storeConfig.currency,
						storeKey: storeConfig.storeKey,
					},
					lineItemIds,
					id: cart.id,
				})
			: undefined;

	const updateLineItems = (lineItems: CartLineItemUpdateInput[]) =>
		cart
			? updateLineItemsMutation({
					// TODO: Get these from a store config context
					storeContext: {
						locale,
						currency: storeConfig.currency,
						storeKey: storeConfig.storeKey,
					},
					lineItems,
					id: cart.id,
				})
			: undefined;

	return {
		removeLineItems,
		updateLineItems,
		cart,
	};
};
